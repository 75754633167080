<template>
  <GcType />
</template>

<script>

import GcType from '@/components/home/GcType.vue'

export default {
  name: 'Home',

  components: {
    GcType,
  },

  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

</style>
