<template>
  <div class="gc-type">
    <div class="gc-container">
      <img @click="goToPage('/service?type=kitchen')" class="gc-item" src="@/assets/images/chuyu_icon.png" alt="">
      <img @click="goToPage('/service?type=build')" class="gc-item" src="@/assets/images/jianzhu_icon.png" alt="">
      <img @click="goToPage('/service?type=green')" class="gc-item" src="@/assets/images/lvhua_icon.png" alt="">
      <img @click="goToPage('/service?type=big')" class="gc-item" src="@/assets/images/dajian_icon.png" alt="">
      <img @click="goToPage('/service?type=recyclable')" class="gc-item" src="@/assets/images/kehuishouwu_icon.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: 'GcType',
  data () {
    return {

    }
  },
  methods: {
    // 跳转页面
    goToPage (path) {
      if (!path) return false
      this.$router.push({ path: path })
    },
  }

}

</script>

<style lang="scss" scoped>

.gc-type {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 63px 0 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  .gc-container {
    flex: 0 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gc-item {
      flex: 0 0 auto;
      width: 210px;
      height: 345px;
      cursor: pointer;
    }
  }
}

</style>
